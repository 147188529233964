import axios from "../instance";

export default {
  //   getAll0401: (companyId) => {
  //     return axios.api
  //       .get(`/getReport?companyId=${companyId}`)
  //       .then((response) => response.data);
  //   },
  //   getAll0402: (companyId) => {
  //     return axios.api
  //       .get(`/getReport?companyId=${companyId}`)
  //       .then((response) => response.data);
  //   },
  getAllDialySales: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reportsDialySales?vanId=${vanId}&selectedDate=${startDate}&companyId=${companyId}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll2_1: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports2_1?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll4_1: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports4_1?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll4_2: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports4_2?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll4_3: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports4_3?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll4_4: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports4_4?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll4_5: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports4_5?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll4_6: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports4_6?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll4_7: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports4_7?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll4_8: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports4_8?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll4_9: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports4_9?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll5_1: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports5_1?vanId=${vanId}?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll5_2: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports5_2?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll5_3: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports5_3?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll6: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports6?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll7: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports7?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll12_1: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports12_1?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll12_2: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports12_2?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll13_1: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports13_1?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll13_2: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports13_2?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll13_3: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports13_3?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  getAll13_4: (vanId, startDate, endDate, companyId) => {
    return axios.api
      .get(
        `/reports/reports13_4?vanId=${vanId}&companyId=${companyId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
};
