import axios from "../instance";

export default {
  getAll: (payment_by, companyId) => {
    return axios.api
      .get(`/transactionNews?payment_by=${payment_by}&companyId=${companyId}`)
      .then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api
      .get(`/transactionNews/${id}`)
      .then((response) => response.data);
  },
  getOneFilterVanId: (id, vanId) => {
    return axios.api
      .get(`/transactionNews/${id}?vanId=${vanId}`)
      .then((response) => response.data);
  },
  search: (body) => {
    return axios.api
      .post("/transactionNews/search", body)
      .then((response) => response.data);
  },
  create: (body) => {
    return axios.api
      .post("/transactionNews", body)
      .then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/transactionNews/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/transactionNews/${id}`)
      .then((response) => response.data);
  },
  // http://localhost:8081/transactionNews/getPackingList?comapnyId=6&selectedDate=2023-03-06&vanId=
  getPrepareItems: (companyId, selectedDate, vanId, startDate, endDate) => {
    // return axios.api.get(`/transactionNews/getPackingList?comapnyId=${companyId}&selectedDate=${selectedDate}&vanId=${vanId}`).then((response) => response.data);
    return axios.api
      .get(
        `/transactionNews/getPackingList?companyId=${companyId}&selectedDate=${selectedDate}&vanId=${vanId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
  vanReports: (selectedDate, vanId, status, startDate, endDate) => {
    return axios.api
      .get(
        `/transactionNews/vanReports?vanId=${vanId}&selectedDate=${selectedDate}&status=${status}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => response.data);
  },
};
